
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import VueKonva from 'vue-konva';
import { AssetsServiceClient } from '@/api/clients/assetsServiceClient';
import { NodeConfig } from 'konva/lib/Node';
import { AssetListItem } from '@/models/assets/assetListItem';
import Konva from 'konva';
import { Sticker } from '@/api/contracts/assets/sticker';
import { AssetAudience } from '@/api/contracts/assets/assetAudience';
import { AssetProductType } from '@/api/contracts/assets/assetProductType';
import debounce from 'lodash/debounce';
import { SocialChannel, SocialChannels } from '@/models/posts/socialChannels';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { CampaignModule } from '@/store/campaignModule';
import { getModule } from 'vuex-module-decorators';
import { Region } from '@/models/stores/regions';
import { DataTableHeader } from 'vuetify';
import { ALLSTORES } from '@/constants';

const assetServiceClient = new AssetsServiceClient();

Vue.use(VueKonva);

/**
 * Interface for the `Audience` and `Product` selects.
 * `value` and `text` are what populate the select options, and the remainder
 * are used when mapping the selected values when doing the final POST
 * to the endpoint.
 */
interface AudienceAndProductSelect {
  value: guid;
  text: string;
  id: guid;
  title: string;
  description: string;
  subCategories: string[];
}

interface StoreSelection {
  id: guid;
  name: string;
  region: Region;
}

const campaignModule = getModule(CampaignModule);

@Component
export default class NewAssetModal extends LoggedInComponentBase {
  public configStage: Konva.ContainerConfig = {
    width: 0,
    height: 0
  };
  public dataURL: string = '';
  public selectedNode: object | null = null;
  public assetTitle: string = '';
  public selectedAudiences: AssetAudience[] = [];
  public selectedProductTypes: AssetProductType[] = [];
  public fileInputImage: File | null = null;
  public isDragging: boolean = false; // is drag-drop zone active - for styling
  public isImageImported: boolean = false;
  public isResetBtnCreated: boolean = false;
  private strokePercentage: number = 0.25; // Width of the rectangle stroke as a percentage of the stage.
  private imageExportSize = 1200; // Size in pixels of the final exported image.
  private showSteps: boolean = false;
  // This rectangle is to display the final exported area to the user. We use
  // the `stroke` to show area of the stage that will NOT be exported.
  public configRect: Konva.RectConfig = {
    width: 0,
    height: 0,
    stroke: 'rgba(0,0,0,.5)',
    strokeWidth: 0,
    draggable: false,
    listening: false
  };

  // basic form validation
  public errors: Array<string> = [];
  private isFormValid(): boolean {
    this.errors = [];
    if (!this.assetTitle) this.errors.push('Asset title required.');
    if (this.showMsoFields && !this.selectedStores.length)
      this.errors.push('Store selection required.');
    if (!this.isImageImported) this.errors.push('Image asset required.');

    // snackbar error feedback
    if (this.errors.length) {
      this.notificationTimeout = '10000';
      this.notificationColour = 'error';
      this.notificationMessage =
        '<strong class="mb-2 d-inline-block">Unable to create new asset.</strong> <br /><ul><li>' +
        this.errors.join('</li><li>') +
        '</li></ul>';
      this.showNotification = true;
    }

    return !this.errors.length;
  }

  // MSO advanced asset modal
  public sharedMsoAssetId: guid = '';
  public get showMsoFields(): boolean {
    return !this.userModule.isViewingSingleStore;
  }
  public availableChannels = SocialChannels;
  public selectedSocialChannels: SocialChannel[] = [];
  public selectedCategory: CampaignCategory | null = null;
  public get categoryFilters(): CampaignCategory[] {
    const filters: CampaignCategory[] = [];

    // Now add a filter for each campaign category
    campaignModule.campaignCategories.forEach(category => {
      filters.push(category);
    });

    return filters;
  }
  public get regions(): Region[] {
    return this.storesModule.regions.filter(region => region !== Region.ALL);
  }
  public selectedRegions: Region[] = [];
  public toggleAllStoresInRegion(region: Region, value: Region | null) {
    const isDeselect: boolean = value === null;
    if (region === Region.ALL) {
      this.selectedStores = isDeselect ? [] : [...this.tableItems];
    } else if (isDeselect) {
      this.selectedStores = this.selectedStores.filter(
        store => store.region !== region
      );
    } else {
      const allStoreOptionsInRegion = this.tableItems.filter(
        row => row.region === region
      );
      allStoreOptionsInRegion.forEach(option => {
        if (!this.selectedStores.find(store => store.id === option.id)) {
          this.selectedStores.push(option);
        }
      });
    }
  }
  public selectedStores: StoreSelection[] = [];
  public tableHeaders: DataTableHeader[] = [
    {
      text: 'Stores',
      value: 'name',
    },
  ];
  public get tableItems(): StoreSelection[] {
    return this.storesModule.userStores
      .filter(store => store.title !== ALLSTORES)
      .map(store => ({ id: store.id, name: store.title, region: store.state }));
  }
  public showSelectAllForRegion(region: Region): boolean {
    return Number(this.storesModule.regionalStoreCount.get(region)) > 1;
  }
  public get showSelectAll(): boolean {
    return this.tableItems.some(row => this.showSelectAllForRegion(row.region));
  }

  // Notification
  public showNotification: boolean = false;
  public notificationMessage: string = '';
  public notificationColour: string = 'green';
  public notificationTimeout: string = '2500';

  public get uploadImageButtonText() {
    return this.fileInputImage?.name || 'Upload an image';
  }

  public $refs!: Vue['$refs'] & {
    imageUploader: HTMLInputElement;
    dropZone: HTMLElement;
  };

  public uploadImage(): void {
    this.$refs.imageUploader.click();
  }

  public get audiences(): AudienceAndProductSelect[] {
    return this.newAssetModalModule.audiences.map(audience => {
      return {
        value: audience.id,
        text: audience.title,
        id: audience.id,
        title: audience.title,
        description: audience.description,
        subCategories: audience.subCategories
      };
    });
  }

  public get productTypes(): AudienceAndProductSelect[] {
    return this.newAssetModalModule.productTypes.map(product => {
      return {
        value: product.id,
        text: product.title,
        id: product.id,
        title: product.title,
        description: product.description,
        subCategories: product.subCategories
      };
    });
  }

  private get konvaStage(): Konva.Stage {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.stage as any).getNode();
  }

  // Get a reference to the Konva Image object.
  private get konvaMainImage(): Konva.Image {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.mainImage as any).getNode();
  }

  // Get a reference to the Konva Image object.
  private get konvaStickerImage(): Konva.Image {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.sticker as any).getNode();
  }

  private get konvaClipRect(): Konva.Rect {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.clipRect as any).getNode();
  }

  private get konvaTransformer(): Konva.Transformer {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.$refs.transformer as any).getNode();
  }

  public get modalVisible(): boolean {
    return this.newAssetModalModule.visible;
  }

  public set modalVisible(value: boolean) {
    if (!value) {
      this.newAssetModalModule.setNewAssetModalVisible(false);
    }
  }

  public get selectedAsset(): AssetListItem | null {
    return this.assetModalModule.selectedAsset;
  }

  public get findStickerSelected(): Sticker | null {
    return this.stickerModalModule.selectedSticker;
  }

  public get selectedSticker(): Sticker | null {
    return this.newAssetModalModule.selectedSticker;
  }

  mounted() {
    this.newAssetModalModule.getAudiences();
    this.newAssetModalModule.getProductTypes();

    // adapt the stage on any window resize
    window.addEventListener(
      'resize',
      debounce(() => {
        this.fitStageIntoParentContainer();
      }, 100)
    );
  }

  public setInitialImagePosition(image: Konva.Image) {
    image.x((this.konvaStage.width() - image.getWidth()) / 2);
    image.y((this.konvaStage.height() - image.getHeight()) / 2);
  }

  public handleTransformEndMainImage(e: NodeConfig) {
    this.newAssetModalModule.setMainImageTransforms({
      rotation: e.target.rotation(),
      scaleX: e.target.scaleX(),
      scaleY: e.target.scaleY()
    });
  }

  public handleTransformEndSticker(e: NodeConfig) {
    this.newAssetModalModule.setStickerTransforms({
      rotation: e.target.rotation(),
      scaleX: e.target.scaleX(),
      scaleY: e.target.scaleY()
    });
  }

  public handleStageMouseDown(e: NodeConfig) {
    // clicked on stage - clear selection
    if (e.target === e.target?.getStage()) {
      this.updateTransformer();
      return;
    }

    // clicked on transformer - do nothing
    const clickedOnTransformer =
      e.target.getParent().getClassName() === 'Transformer';
    if (clickedOnTransformer) {
      return;
    }

    this.selectedNode = e.target;
    this.updateTransformer();
  }

  public updateTransformer() {
    // Here we need to manually attach or detach the Transformer node
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transformer = this.$refs.transformer as any;
    const transformerNode = transformer.getNode();

    // Do nothing if selected node is already attached
    if (this.selectedNode === transformerNode.node()) {
      return;
    }

    if (this.selectedNode) {
      // attach to another node
      transformerNode.nodes([this.selectedNode]);
    } else {
      // remove transformer
      transformerNode.nodes([]);
    }
    transformerNode.getLayer().batchDraw();
  }

  public setImageDimensions() {
    const stageWidth: number = this.konvaStage.width();
    const stageHeight: number = this.konvaStage.height();

    const imageWidth: number = this.konvaMainImage?.width();
    const imageHeight: number = this.konvaMainImage?.height();

    const isSquare: boolean = imageWidth === imageHeight;
    const isLandscape: boolean = !isSquare && imageWidth > imageHeight;
    const isPortrait: boolean = !isSquare && imageHeight > imageWidth;

    let widthFit: number = 0;
    let heightFit: number = 0;
    let scale = 1;

    widthFit = stageWidth / imageWidth;
    heightFit = stageHeight / imageHeight;

    // Calculate ratios so we can work out the correct scale
    if (isLandscape) {
      scale = widthFit;
    } else if (isPortrait) {
      scale = heightFit;
    } else {
      scale = heightFit;
    }

    const newWidth = imageWidth * scale;
    const newHeight = imageHeight * scale;

    this.konvaMainImage?.width(newWidth);
    this.konvaMainImage?.height(newHeight);
    this.konvaMainImage?.draw();
  }

  /**
   * Handler for when an image is selected from the users computer.
   */
  public imageSelectedHandler(e: Event) {
    const target = e.target as HTMLInputElement;
    const file = target.files && target.files[0];
    this.fileInputImage = file;
    const image = new Image();

    image.onload = () => {
      this.newAssetModalModule.setSelectedImage(image);
      this.konvaMainImage?.width(image.width);
      this.konvaMainImage?.height(image.height);
      this.isImageImported = true;

      setTimeout(() => {
        this.setImageDimensions();
        this.setInitialImagePosition(this.konvaMainImage);
        this.updateTransformer();
      });
    };

    // Create a URL based on the File object passed when an image is selected,
    // and set the image src to that URL.
    if (file) image.src = URL.createObjectURL(file);
  }

  public goToStep2() {
    this.newAssetModalModule.setCurrentStep(2);
  }

  public goToStep3() {
    this.newAssetModalModule.setCurrentStep(3);
  }

  public async finalStepCompleted() {
    // Detach the transformer so the handles don't get included in the image.
    this.konvaTransformer.detach();

    const strokeWidth = this.konvaStage.width() * this.strokePercentage;

    // We want a 1200px image, so need to calculate the pixel ratio that will result in an image that size.
    const exportPixelRatio =
      this.imageExportSize / (this.konvaStage.width() - strokeWidth);

    // This will convert a section of the stage to a base64 encoded string:
    this.dataURL = this.konvaStage.getChildren()[0].toDataURL({
      mimeType: 'image/jpeg',
      quality: 0.85,
      x: strokeWidth / 2,
      y: strokeWidth / 2,
      width: this.konvaStage.width() - strokeWidth,
      height: this.konvaStage.height() - strokeWidth,
      pixelRatio: exportPixelRatio
    });

    try {
      // Reset time to show notification
      this.notificationTimeout = '2500';
      this.showNotification = false;

      if (this.isFormValid()) {
        const asset: AssetListItem = {
          id: '',
          title: this.assetTitle.trim(),
          blobUrl: '',
          category: '',
          isVideoAsset: false,
          isYoutubeVideo: false,
          videoCode: '',
          audiences: this.selectedAudiences.map(audience => {
            return {
              description: audience.description,
              subCategories: audience.subCategories,
              id: audience.id,
              title: audience.title
            };
          }),
          productTypes: this.selectedProductTypes.map(product => {
            return {
              description: product.description,
              subCategories: product.subCategories,
              id: product.id,
              title: product.title
            };
          }),
          campaignCategories: [
            {
              id: this.selectedCategory?.id,
              title: this.selectedCategory?.title
            }
          ],
          isRecommended: false,
          imageData: this.dataURL,
          fileName: `${this.assetTitle.trim().replace(/\s/g, '_')}.jpg`,
          availableChannels: this.selectedSocialChannels
        };

        if (this.userModule.isViewingSingleStore) {
          await assetServiceClient.postAsset({
            storeId: this.userModule.currentStore.id,
            asset
          });
        } else {
          await Promise.allSettled(
            this.selectedStores.map(store => {
              return assetServiceClient.postMsoAsset({
                asset,
                msoGuid: this.sharedMsoAssetId,
                storeId: store.id
              });
            })
          );
        }

        this.notificationMessage =
          'Your new asset has been uploaded successfully';
        this.notificationColour = 'success';
        this.showNotification = true;

        setTimeout(() => {
          this.resetAndCloseModal();
        }, parseInt(this.notificationTimeout));
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.message !== ''
      ) {
        this.notificationMessage = err.response.data.message;
      } else {
        this.notificationMessage = 'There was an error uploading your asset.';
      }

      this.notificationTimeout = '10000';
      this.notificationColour = 'error';
      this.showNotification = true;
    } finally {
      // clear msoGuid once used
      this.sharedMsoAssetId = '';
    }
  }

  public resetAndCloseModal() {
    this.fileInputImage = null;
    this.$refs.imageUploader.value = '';
    this.newAssetModalModule.resetMainImage();
    this.newAssetModalModule.resetSticker();
    this.newAssetModalModule.resetState();
    this.konvaTransformer.detach();
    this.selectedNode = null;
    (this.$refs.assetDetailsForm as VForm).reset();
    this.selectedSocialChannels = [];
    this.selectedCategory = null;
    this.selectedStores = [];

    this.isImageImported = false;
    this.modalVisible = false;
  }

  public selectAssetHandler() {
    this.newAssetModalModule.resetMainImage();
    this.assetModalModule.setAssetModalVisible(true);
  }

  public showGetStickers() {
    this.stickerModalModule.setFindStickerModalVisible(true);
  }

  public stickerDragEnd(e: NodeConfig) {
    this.newAssetModalModule.setStickerPosition({
      x: e.target.position().x,
      y: e.target.position().y
    });
  }

  public mainImageDragEnd(e: NodeConfig) {
    this.newAssetModalModule.setMainImagePosition({
      x: e.target.position().x,
      y: e.target.position().y
    });
  }

  public onDropZoneDragOver(e: DragEvent) {
    e.preventDefault();
    if (!this.isDragging) {
      this.isDragging = true;
    }
  }

  public onDropZoneDragLeave(e: { target: HTMLElement }) {
    if (e.target.classList.contains('drop-zone')) {
      this.isDragging = false;
    }
  }

  public onDropZoneDrop(e: DragEvent) {
    // TODO: DRY refactor with imageSelectedHandler

    e.preventDefault();
    this.isDragging = false;
    if (!e.dataTransfer?.files) return;

    const file = e.dataTransfer?.files.item(0);
    if (!['image/png', 'image/jpeg'].includes(file?.type ?? '')) {
      alert('File type not supported');
      return;
    }

    this.fileInputImage = file;
    const image = new Image();

    image.onload = () => {
      this.newAssetModalModule.setSelectedImage(image);
      this.konvaMainImage?.width(image.width);
      this.konvaMainImage?.height(image.height);
      this.isImageImported = true;

      setTimeout(() => {
        this.setImageDimensions();
        this.setInitialImagePosition(this.konvaMainImage);
        this.updateTransformer();
      });
    };

    // Create a URL based on the File object passed when an image is selected,
    // and set the image src to that URL.
    if (file) image.src = URL.createObjectURL(file);
  }

  // this is poor but the only way I can think to add this btn where it needs to be.
  // must be outside of drop-zone div that gets z-index:-1, and any foreign html within <v-stage> won't render
  private createResetBtn(): void {
    if (this.isResetBtnCreated) return;
    const btn = document.createElement('button');
    btn.classList.add('reset-img-btn');
    btn.appendChild(document.createTextNode('Clear image'));
    btn.addEventListener('click', this.onResetBtnClick);
    const stage = (this.$refs.stage as Vue).$el as HTMLElement;
    stage.appendChild(btn);
    this.isResetBtnCreated = true;
  }

  private onResetBtnClick() {
    this.fileInputImage = null;
    this.$refs.imageUploader.value = '';
    this.newAssetModalModule.resetMainImage();
    this.newAssetModalModule.resetSticker();
    this.newAssetModalModule.resetState();
    this.isImageImported = false;

    const transformer = this.$refs.transformer as any;
    const transformerNode = transformer.getNode();
    transformerNode.nodes([]);
  }

  /**
   * https://konvajs.org/docs/sandbox/Responsive_Canvas.html
   */
  public fitStageIntoParentContainer() {
    if (!this.$refs.stageCard) return;
    const container = (this.$refs.stageCard as Vue).$el as HTMLElement;
    const stageWidth = ((this.$refs.stage as Vue).$el as HTMLElement)
      .offsetWidth;
    const stageHeight = ((this.$refs.stage as Vue).$el as HTMLElement)
      .offsetHeight;

    // now we need to fit stage into parent
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    // to do this we need to scale the stage
    const scale = containerWidth / stageWidth;

    const newWidth =
      containerWidth > containerHeight ? containerHeight : stageWidth * scale;
    let newHeight =
      window.outerHeight > containerHeight
        ? containerHeight
        : stageHeight * scale;

    if (containerWidth < containerHeight) {
      newHeight = stageWidth * scale;
    }

    this.configStage.width = newWidth;
    this.configStage.height = newHeight;

    // set dimensions for drop-zone overlay
    this.$refs.dropZone.style.width = newWidth + 'px';
    this.$refs.dropZone.style.height = newHeight + 'px';

    if (this.configStage.width) {
      this.konvaStage.width(this.configStage.width);
    }
    if (this.configStage.height) {
      this.konvaStage.height(this.configStage.height);
    }
    this.konvaStage.draw();

    // Adjust the rect stroke width
    this.configRect.width = newWidth;
    this.configRect.height = newHeight;
    this.configRect.strokeWidth = newWidth * this.strokePercentage;

    if (this.configRect.width) {
      this.konvaClipRect.width(this.configRect.width);
    }
    if (this.configRect.height) {
      this.konvaClipRect.height(this.configRect.height);
    }
    this.konvaClipRect.draw();
  }

  /**
   * Watcher for when a new image is selected from the asset library.
   * @param asset
   * @private
   */
  @Watch('selectedAsset')
  private async onSelectedAssetChanged(asset: AssetListItem) {
    if (!this.modalVisible) return;
    const image = new Image();
    image.crossOrigin = 'Anonymous';

    image.onload = () => {
      this.newAssetModalModule.setSelectedImage(image);
      this.konvaMainImage?.width(image.width);
      this.konvaMainImage?.height(image.height);
      this.isImageImported = true;

      setTimeout(() => {
        this.setImageDimensions();
        this.setInitialImagePosition(this.konvaMainImage);
        this.updateTransformer();
      });
    };

    // Create a URL based on the File object passed when an image is selected,
    // and set the image src to that URL.
    image.src = asset.blobUrl;
  }

  @Watch('findStickerSelected')
  private async onSelectedStickerChanged(selectedSticker: Sticker) {
    const image = new Image();
    image.crossOrigin = 'Anonymous';

    image.onload = () => {
      this.newAssetModalModule.setSticker(image);
      this.konvaStickerImage?.width(image.width);
      this.konvaStickerImage?.height(image.height);
    };

    image.src = selectedSticker.blobUrl;
  }

  @Watch('modalVisible')
  private async onDialogChanged(visible: boolean) {
    if (visible) {
      // Dialog has been opened
      setTimeout(() => {
        this.fitStageIntoParentContainer();
        this.createResetBtn();
      });

      // request a new shared id for grouping mso assets
      if (
        !this.userModule.isViewingSingleStore &&
        this.sharedMsoAssetId === ''
      ) {
        this.sharedMsoAssetId = await assetServiceClient.getSharedMsoAssetId();
      }
    }
  }
}
