
import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';

@Component
export default class MainNavMob extends LoggedInComponentBase {
  public menu: boolean = false;

  public get routeName(): string {
    return this.$route.name?.toLowerCase() || '';
  }

  public get pageTitle(): string {
    if (this.routeName.includes('home')) {
      return 'This Week';
    }
    if (this.routeName.includes('assetlibrary')) {
      return 'Asset Library';
    }
    if (this.routeName.includes('helpandfaqs.faqs')) {
      return 'FAQs';
    }
    if (this.routeName.includes('settings')) {
      return 'Settings';
    }
    return '';
  }

  public get isRightDrawerToggleVisible(): boolean {
    return (
      !this.$route.name?.includes('helpAndFaqs') &&
      !this.$route.name?.includes('home')
    );
  }

  public onCameraBtnClick() {
    this.$router.push({ path: '/create-post', query: { camera: 'true' } });
  }
}
