import { SocialChannel } from './../posts/socialChannels';
import { SortKey } from './assetsFilters';
import { Region } from '@/models/stores/regions';
import { PaginationRequest } from '@/models/pagination/paginationRequest';
import { AssetListRequestParams } from '@/models/assets/assetListRequestParams';

export class AssetListRequest {
  campaignId?: guid = '';
  campaignCategoryId?: guid = '';
  campaignSubCategoryId?: guid = '';
  productTypeCategoryId?: guid = '';
  audienceCategoryId?: guid = '';
  query?: string = '';
  includeRecommended?: boolean = true;
  pagination: PaginationRequest = { limit: 0, start: 0, sort: '' };
  state?: Region = '' as Region;
  sort?: SortKey = '';
  channel?: SocialChannel;

  constructor(params?: AssetListRequestParams) {
    Object.assign(this, params);
  }
}
