
import { Component, Watch } from 'vue-property-decorator';
import PostModal from './postModal.vue';
import { ALLSTORES, MsoPostTableHeaders } from '@/constants';
import { DataTableHeader } from 'vuetify';
import { DataTableItem, StoreChannelsMap } from '@/models/posts/postModal';
import StoresTable from './storesTable.vue';
import { SocialChannel, SocialChannels } from '@/models/posts/socialChannels';

@Component({
  components: { StoresTable }
})
export default class MsoPostModal extends PostModal {
  public tableLoading = false;
  public tableLoaded = false;
  public tableHeaders: DataTableHeader[] = MsoPostTableHeaders;
  public channelSelectionTableItems: DataTableItem[] = [];
  public channelReviewTableItems: DataTableItem[] = [];
  public allChannels: SocialChannel[] = [];
  private msoPostId: guid = '';

  public async msoFormSubmit(): Promise<void> {
    const storesPostingTo = this.selectedStoreChannelsMap;
    await this.formSubmit(this.msoPostId, storesPostingTo);
  }

  public get currentStep(): number {
    return this.postModalModule.currentStep;
  }

  public get hintText(): string {
    const hint = 'To save a draft or schedule a post, please select a store';
    return hint + (this.currentStep !== 2 ? ' in step 2.' : '.');
  }

  public get showHint(): boolean {
    return this.isLastStep
      ? this.draftButtonDisabled || this.publishButtonDisabled
      : this.draftButtonDisabled;
  }

  public get allowedToProcceed(): boolean {
    return true;
  }

  /* Happens only on uer actions, as apposed to programatical stepping
   * (e.g. jumping to error step on validation fails)
   * Reset form validation so that custom validation watchers gets triggered
   * on every save/schedule action.
   */
  public step(step: number): void {
    this.resetFormValidation();
    this.postModalModule.stepTo(this.currentStep + step);
  }

  public get isLastStep(): boolean {
    return this.currentStep === 3;
  }

  public get stepTitle(): string {
    const stepTitles = [
      this.modalTitle,
      'Select where to post',
      'Review and schedule'
    ];

    return stepTitles[this.currentStep - 1];
  }

  public toggleChannelForStore(payload: {
    tableItem: DataTableItem;
    channel: SocialChannel;
    value: boolean;
  }): void {
    const { tableItem, channel, value } = payload;
    const key = channel.toLocaleLowerCase();

    if (tableItem) {
      // @ts-expect-error
      tableItem[key].value = value;
      if (this.state === this.stateVals.EXISTING_POST) {
        const editedPostStoreChannels = this.editedPost?.msoPostStores?.find(
          store => store.storeId === tableItem.id
        );
        // @ts-expect-error
        tableItem[key].unpublishing =
          editedPostStoreChannels?.postedChannels.includes(channel) && !value;
      }
    }
  }

  public toggleChannelForAll(value: boolean, channel: SocialChannel): void {
    const property = channel.toLocaleLowerCase();
    this.channelSelectionTableItems.forEach(tableItem => {
      // @ts-expect-error
      if (!tableItem[property].disabled) {
        this.toggleChannelForStore({ tableItem, channel, value });
      }
    });
  }

  private get selectedStoreChannelsMap(): StoreChannelsMap {
    const result: StoreChannelsMap = new Map();
    this.selectedTableItems.forEach(store => {
      const unpublishChannels: SocialChannel[] = [];
      const selectedChannels: SocialChannel[] = [];
      const isCreatingNewPost = store.isCreatingNewPost;
      SocialChannels.forEach(channel => {
        const key = channel.toLowerCase();
        // @ts-expect-error
        store[key].value && selectedChannels.push(channel);
        // @ts-expect-error
        store[key].unpublishing && unpublishChannels.push(channel);
      });
      result.set(store.id, {
        selectedChannels,
        unpublishChannels,
        isCreatingNewPost
      });
    });
    return result;
  }

  private get isSelectingSocialChannels(): boolean {
    return this.currentStep === 2;
  }

  private async loadAllStoresChannels(): Promise<void> {
    const storeIds = this.channelSelectionTableItems.map(item => item.id);
    const allStoresSocialChannels = await this.storesModule.getAllStoresChannels(
      storeIds
    );

    this.channelSelectionTableItems.forEach((row, i) => {
      const loadingResult = allStoresSocialChannels[i];
      // TBD - render stores channels as disabled if failed loading from the server
      if (loadingResult.status === 'rejected') {
        row.facebook.failedLoading = true;
        row.instagram.failedLoading = true;
      } else {
        row.facebook.disabled =
          !loadingResult.value.includes(SocialChannel.Facebook) ||
          !this.isChannelAvailable(SocialChannel.Facebook);
        row.instagram.disabled =
          !loadingResult.value.includes(SocialChannel.Instagram) ||
          !this.isChannelAvailable(SocialChannel.Instagram);
      }
    });
  }

  private get selectedTableItems(): DataTableItem[] {
    const isStoreSelected = (store: DataTableItem) =>
      store.facebook.value || store.instagram.value;

    const isUnpublishing = (store: DataTableItem) =>
      store.facebook.unpublishing || store.instagram.unpublishing;

    return this.channelSelectionTableItems.filter(
      store => isStoreSelected(store) || isUnpublishing(store)
    );
  }

  private mounted(): void {
    this.initChannelSelectionTable();
  }

  public isChannelAvailable(channel: SocialChannel): boolean {
    // Take into account the recommended templates's available channels if opened one
    return this.postModalModule.isRecommended
      ? this.postModalModule.availableChannels.includes(channel)
      : true;
  }

  private initChannelSelectionTable(): void {
    this.channelSelectionTableItems = this.storesModule.userStores
      .filter(store => store.title !== ALLSTORES)
      .map(store => ({
        id: store.id,
        name: store.title,
        region: store.state,
        facebook: { disabled: true, value: false },
        instagram: { disabled: true, value: false },
        isCreatingNewPost: true
      }));

    if (this.state === this.stateVals.EXISTING_POST) {
      this.fillInPostedChannels();
    }
  }

  private fillInPostedChannels(): void {
    this.channelSelectionTableItems.forEach(row => {
      const correspondingPostStore = this.editedPost?.msoPostStores?.find(
        store => store.storeId === row.id
      );

      // The post being edited was saved to the store in this table row
      if (correspondingPostStore) {
        // The store will not be creating (POST) a new post, but editing (PUT)
        row.isCreatingNewPost = false;
        SocialChannels.forEach(ch => {
          const key = ch.toLowerCase();
          // @ts-expect-error
          row[key].value = correspondingPostStore.postedChannels.includes(ch);
        });
      }
    });
  }

  protected get selectedAnyChannel(): boolean {
    return this.selectedTableItems.length > 0;
  }

  @Watch('currentStep')
  private async onStepChange(): Promise<void> {
    if (this.isSelectingSocialChannels) {
      this.tableLoading = true;
      await this.loadAllStoresChannels();
      this.tableLoaded = true;
    }
  }

  @Watch('channelSelectionTableItems', { deep: true })
  private onChannelToggled(): void {
    this.channelReviewTableItems = this.selectedTableItems;
  }

  @Watch('tableLoading')
  private onTableLoading(): void {
    if (this.tableLoading) {
      this.tableLoaded = false;
    }
  }

  @Watch('tableLoaded')
  private onTableLoaded(): void {
    if (this.tableLoaded) {
      this.tableLoading = false;
    }
  }

  @Watch('selectedAssetError')
  @Watch('requiredValidationError')
  private onAssetValidationError(error: boolean): void {
    if (error && this.currentStep !== 1) {
      this.postModalModule.stepTo(1);
    }
  }

  @Watch('dateTimeError')
  private onDateTimeValidationError(error: boolean): void {
    if (error && !this.isLastStep) {
      this.postModalModule.stepTo(3);
    }
  }

  @Watch('dialog')
  private async onDialogToggled(open: boolean): Promise<void> {
    if (open) {
      this.initChannelSelectionTable();
      this.msoPostId =
        this.state === this.stateVals.EXISTING_POST
          ? this.postModalModule.msoPostId
          : await this.postModalModule.getMsoPostId();
    } else {
      this.channelSelectionTableItems = [];
      this.allChannels = [];
      this.postModalModule.resetState();
    }
  }
}
